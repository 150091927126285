import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';

export const fetchBookings = async () => {
  try {
    console.log('Versuche Buchungen abzurufen...');
    console.log('API_BASE_URL:', API_BASE_URL);
    const response = await axios.get(`${API_BASE_URL}/api/bookings`);
    console.log('Serverantwort:', response.data);
    return response.data;
  } catch (error) {
    console.error('Fehler beim Abrufen der Buchungen:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    } else if (error.request) {
      console.error('Request made but no response received:', error.request);
    } else {
      console.error('Error setting up the request:', error.message);
    }
    console.error('Error config:', error.config);
    throw error;
  }
};
