import React from 'react';
import moment from 'moment';

function BookingItem({ title, booking, className }) {
  return (
    <div className={`booking-item ${className}`}>
      <h4>{title}</h4>
      {booking ? (
        <>
          <p className="booking-time">
            {moment(booking.startDate).format('DD.MM.YYYY HH:mm')} - {moment(booking.endDate).format('HH:mm')}
          </p>
          <p className="booking-name">{booking.summary}</p>
        </>
      ) : (
        <p className="no-booking">Keine Buchung</p>
      )}
    </div>
  );
}

function RangeBookings({ range, bookings, isAuthenticated, loading, error }) {
  const currentBooking = bookings.find(booking => 
    moment().isBetween(booking.startDate, booking.endDate)
  );
  
  const upcomingBookings = bookings
    .filter(booking => moment(booking.startDate).isAfter())
    .sort((a, b) => moment(a.startDate).diff(moment(b.startDate)))
    .slice(0, 2);

  const lastPastBooking = bookings
    .filter(booking => moment(booking.endDate).isBefore())
    .sort((a, b) => moment(b.endDate).diff(moment(a.endDate)))[0];

  const getMaxStands = (range) => {
    switch(range) {
      case '10m': return 8;
      case '25m': return 6;
      case '50m': return 6;
      case '100m': return 3;
      default: return 0;
    }
  };

  const getBookedStands = () => {
    return currentBooking ? 1 : 0;
  };

  const availableStands = getMaxStands(range) - getBookedStands();

  return (
    <div className="range-bookings">
      <h3>{range} Stand</h3>
      {!isAuthenticated ? (
        <p>Bitte melden Sie sich an, um Buchungen zu sehen.</p>
      ) : loading ? (
        <div className="loading">
          <div className="loading-circle"></div>
        </div>
      ) : error ? (
        <p className="error">{error}</p>
      ) : (
        <>
          <BookingItem title="Vergangene Buchung" booking={lastPastBooking} className="past" />
          <BookingItem title="Aktuelle Buchung" booking={currentBooking} className="current" />
          <BookingItem title="Nächste Buchung" booking={upcomingBookings[0]} className="next" />
          <BookingItem title="Übernächste Buchung" booking={upcomingBookings[1]} className="after-next" />
        </>
      )}
      <div className="available-stands">
        Aktuell {availableStands} {availableStands === 1 ? 'Stand' : 'Stände'} ohne Standbuchungen
      </div>
    </div>
  );
}

export default RangeBookings;
