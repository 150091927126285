import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import 'moment/locale/de';
import RangeBookings from './components/RangeBookings';
import { fetchBookings } from './services/api';
import LoginModal from './components/LoginModal';
import './App.css';

moment.locale('de');

const ranges = ['10m', '25m', '50m', '100m'];

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentDateTime, setCurrentDateTime] = useState(moment());
  const [bookings, setBookings] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const checkAuthStatus = useCallback(() => {
    const authData = JSON.parse(localStorage.getItem('authData'));
    if (authData && authData.expiresAt > Date.now()) {
      setIsAuthenticated(true);
    } else {
      localStorage.removeItem('authData');
      setIsAuthenticated(false);
    }
  }, []);

  const getBookings = useCallback(async () => {
    if (!isAuthenticated) return;
    try {
      setLoading(true);
      console.log('Fetching bookings...');
      const data = await fetchBookings();
      console.log('Bookings fetched successfully:', data);
      setBookings(data);
      setError(null);
    } catch (err) {
      console.error('Error fetching bookings:', err);
      console.error('Error details:', JSON.stringify(err, null, 2));
      setError(`Fehler beim Laden der Buchungen: ${err.message}`);
    } finally {
      setLoading(false);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    checkAuthStatus();
  }, [checkAuthStatus]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(moment());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      getBookings();
    }
  }, [isAuthenticated, getBookings]);

  const handleLogin = (email, password) => {
    // Hier würden Sie normalerweise eine API-Anfrage zur Authentifizierung machen
    // Für dieses Beispiel setzen wir einfach isAuthenticated auf true
    setIsAuthenticated(true);
    const sixMonthsInMilliseconds = 6 * 30 * 24 * 60 * 60 * 1000; // Ungefähr 6 Monate
    const expiresAt = Date.now() + sixMonthsInMilliseconds;
    localStorage.setItem('authData', JSON.stringify({ expiresAt }));
  };

  return (
    <div className={`App ${!isAuthenticated ? 'blurred' : ''}`}>
      {!isAuthenticated && <LoginModal onLogin={handleLogin} />}
      <header>
        <h1>Standbuchungen Astheimer Schützenverein 1958 e.V.</h1>
        <div className="current-date-time">{currentDateTime.format('dddd, DD.MM.YYYY HH:mm:ss')}</div>
      </header>
      {loading ? (
        <div className="loading">
          <div className="loading-circle"></div>
        </div>
      ) : error ? (
        <div className="error">{error}</div>
      ) : (
        <div className="ranges-container">
          {ranges.map(range => (
            <RangeBookings 
              key={range} 
              range={range} 
              bookings={isAuthenticated ? (bookings[range] || []) : []} 
              isAuthenticated={isAuthenticated}
              loading={loading}
              error={error}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default App;
